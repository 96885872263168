/*==================================
payment info
==================================*/
.paymentInfo
{
	position: relative;
}

.paymentInfo_createInvoice
{
	position: absolute;
	bottom: 10px;
	left: 10px;
}

.paymentInfo_orgSettings
{
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.paymentInfo_owner
{
	position: absolute;
	top: calc(30% - 10px);
	left: calc(70%);
}
/*==================================
item status
==================================*/
.itemStatus
{
    width: 24px;
    height: 24px;
    fill: #fff;
}
/*==================================
organization info
==================================*/
.orgInfo
{
	border: var(--ML_input_border);
	border-radius: var(--ML_input_border_radius);
	min-height: 35px;
	width: 100%;
	padding: 10px;
	background: transparent;
}